.marquee-container {
    width: 100%;
    overflow: hidden;
  }

  .marquee-content {
    display: flex;
    animation: marquee 15s linear infinite;
  }
  
  .marquee-image {
    width: 150px;
    height: 120px;
    margin-top: 20px;
    margin-bottom: 30px; /* Space between images */
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  