.food-section {
    background-color: #28a745; /* Green background */
    padding: 40px 0;
    text-align: center;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .text-section h2 {
    font-size: 32px;
    color: white;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
  }
  
  .text-section a.order-btn {
    background-color: #f39c12;
    color: white;
    font-size: 16px;
    padding: 12px 30px;
    border-radius: 50px;
    text-decoration: none;
    font-weight: bold;
    display: inline-block;
    margin-top: 10px;
  }
  
  .logos {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  .logos .logo {
    width: 80px;
    height: auto;
  }
  