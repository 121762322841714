.mobile{
  height: 400px;
  width: 200px;

}

.clip{
  clip-path: polygon(13.6% 41.3%, 85.5% 19%, 100% 15%, 100.1% 100.3%, 85% 100%, 15% 100%, 0% 99.8%, 0% 47%);
  background-color: rgb(44 167 33 / var(--tw-bg-opacity));
  border-radius: 50px;
  width: 1380px;

}

